// setPrototypeOf
(function(){
	Object.setPrototypeOf = Object.setPrototypeOf || function(obj, proto) {
		obj.__proto__ = proto;
		return obj; 
	}
}());

// https://github.com/blueimp/JavaScript-Canvas-to-Blob
(function() {
	var toBlob = function(uri) {
		var mime = uri.split(',')[0].split(':')[1].split(';')[0];
		var bytes = atob(uri.split(',')[1]);
		var len = bytes.length;
		var buffer = new window.ArrayBuffer(len);
		var arr = new window.Uint8Array(buffer);
	
		for (var i = 0; i < len; i++) {
			arr[i] = bytes.charCodeAt(i);
		}
	
		return new Blob([arr], { type: mime });
	};
	
	var init = function() {
		if (!module.exports.supported) return;
		var CanvasPrototype = window.HTMLCanvasElement.prototype;
		
		if (!CanvasPrototype.toBlob && CanvasPrototype.toDataURL) {
			CanvasPrototype.toBlob = function(callback, type, quality) {
			callback(toBlob(this.toDataURL(type, quality)));
			};
		}
	};
	
	// IE >= 10, most modern browsers
	// The Blob type can't be polyfilled, which is why there aren't any polyfills for TypedArrays for older IE's
	var supported =
	typeof window.HTMLCanvasElement !== 'undefined' &&
	typeof window.atob !== 'undefined' &&
	typeof window.Blob !== 'undefined' &&
	typeof window.ArrayBuffer !== 'undefined' &&
	typeof window.Uint8Array !== 'undefined';
	
	window.toBlob = toBlob;
	window.toBlob.init = init;
	window.toBlob.supported = supported;
}());

// https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
(function() {
	if (!Object.entries)
  		Object.entries = function( obj ){
		var ownProps = Object.keys( obj ),
			i = ownProps.length,
			resArray = new Array(i); // preallocate the Array
		while (i--)
		resArray[i] = [ownProps[i], obj[ownProps[i]]];
		
		return resArray;
	};
}());
